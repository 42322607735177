<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.certificates">
			<template #title>Certificates</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'CertificatesNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="certificatesFiltered" item-key="name" :search="search" @click:row="editCertificate" :item-class="() => 'hover-pointer'">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="auto">
							<v-checkbox v-model="includeArchived" label="Show archived"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>

				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn icon @click="editCertificate(item.id)">
						<v-icon>{{$icons.edit}}</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "CertificatesIndex",
	computed:{
		certificates: sync('certificates/certificates'),
		certificatesFiltered(){
			return this.certificates.filter(cert => {
				if(!this.includeArchived && cert.status === 'Archived') return false
				return this.filterOnlyCapsText(cert.name, this.search, cert)
			})
		}
	},
	data: () => ({
		search: '',
		includeArchived: false,
		headers:[
			{
				text: 'Name',
				align: 'start',
				sortable: true,
				value: 'name',
			},
			{
				text: 'Internal name',
				align: 'start',
				sortable: true,
				value: 'internal_name',
			},
			{
				text: 'Description',
				align: 'start',
				sortable: true,
				value: 'description',
			},
			{
				text: 'Status',
				align: 'start',
				sortable: true,
				value: 'status',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.$store.set('certificates/getCertificates!', null)
		},
		editCertificate(data){
			this.$router.push({name:'CertificatesEdit', params: {id: data.id}})
		},
		filterOnlyCapsText (value, search, item) {
			search = search.toString().toLocaleLowerCase()
			value = value.toString().toLocaleLowerCase()
			return value != null &&
				search != null &&
				value.indexOf(search) !== -1
		},
	}
}
</script>